


























































































































































































































































































































































































import { errorNotification, successNotification, warningNotification } from '@/includes/NotificationService'
import { Board, Conditions, PostToCreate, Style, Target, WatermarkType } from '@/views/posts-planner/posts.types'
import Api from '@/includes/logic/Api'
import { InputSetups } from '@/mixins/input-setups'
import PeriodSettings from '@/views/posts-planner/components/Post/Period/PeriodSettings.vue'
import PostStylesSetup from '@/views/posts-planner/components/PostStylesSetup.vue'
import Quizes from '@/views/posts-planner/components/Quizes.vue'
import { BRAND_TAG } from '@/includes/constants';
import PostTime from '@/views/posts-planner/components/PostTime.vue'
import NewActionsList from "@/views/posts-planner/NewActionsList";
import { defaultButtonBuilders } from "@/includes/buttons";

import { FieldData, SelectOptionData } from 'piramis-base-components/src/components/Pi/types'
import { UseFields } from 'piramis-base-components/src/components/Pi/index'
import Accordion from 'piramis-base-components/src/components/Accordion/Accordion.vue'
import { SelectOption } from 'piramis-base-components/src/logic/types'
import Tags from 'piramis-base-components/src/components/Tags/Tags.vue'
import { MessageEditorWithMediaTab } from 'piramis-base-components/src/components/NewMessageEditors/types'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import { FileType } from 'piramis-base-components/src/components/File/types'
import TelegramPostPreview from 'piramis-base-components/src/shared/modules/posting/PostPreview/TelegramPostPreview.vue'
import { IPostMessage, PostType, Schedule } from "piramis-base-components/src/shared/modules/posting/types";
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import { isPostPinned } from "piramis-base-components/src/shared/modules/posting/PostPreview/includes/helpers";
import ModelSetter from "piramis-base-components/src/Mixins/ModelSetter";
import MultiMessageEditorWithMediaInput
  from 'piramis-base-components/src/components/Pi/fields/NewMultiMessageEditorWithMediaInput/MultiMessageEditorWithMediaInput.vue'
import {
  ButtonTypes
} from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/types'
import MessageEditorWithMediaInput
  from 'piramis-base-components/src/components/Pi/fields/NewMessageEditorWithMediaInput/MessageEditorWithMediaInput.vue'
import SelectPostAction from 'piramis-base-components/src/shared/modules/posting/PostActions/PostAction/SelectPostAction.vue'
import SelectPostReaction from 'piramis-base-components/src/shared/modules/posting/PostActions/PostReaction/SelectPostReaction.vue'

import { Mixins, Watch } from 'vue-property-decorator'
import Component from 'vue-class-component'
import { cloneDeep, snakeCase } from 'lodash'
import axios from 'axios'
import moment from 'moment'
import PostsApi from '@/includes/Api/Posts.api'

enum ScheduleType {
  PostNow = 'PostNow',
  Draft = 'Draft',
  Test = 'Test'
}

type TargetTopicsOption = {
  id: Target['id']
  title: Target['title']
  topicsOptions: Array<SelectOption>
}

type TargetTopicsOptionParsedValue = {
  target: number
  topic: number
}

@Component({
  methods: {
    defaultButtonBuilders,
    isPostPinned
  },
  'components': {
    Quizes,
    PeriodSettings,
    Accordion,
    PostStylesSetup,
    Tags,
    PostTime,
    TelegramPostPreview,
    ConfigField,
    SelectPostAction,
    SelectPostReaction,
    MultiMessageEditorWithMediaInput,
    MessageEditorWithMediaInput
  },
  data() {
    return {
      FileType,
      BRAND_TAG,
      MessageEditorWithMediaTab,
      PostType,
      ButtonTypes
    }
  }
})
export default class Post extends Mixins(InputSetups, UseFields, NewActionsList, ModelSetter) {
  postType: PostType = PostType.Post

  scheduleType = '' as ScheduleType

  arePostActionSet = false

  arePostReactionSet = false

  isObjectSet = false

  rerenderPostStylesSetup = 0

  areOriginsOpen = false

  isExtendedSettingsOpen = false

  initConditions: Conditions[] = []

  originSelectRerenderKey = 0

  targetSelectRerenderKey = 0

  styleVariant = 'blank'

  selectStyleVariants: Array<SelectOption> =
    [
      // { 'label': this.$t('post_page_style_empty'), 'value': 'empty' },
      { 'label': this.$t('post_page_style_blank'), 'value': 'blank' },
    ]

  isCreateButtonDisabled = true

  removePreviousMessage = false

  get firstMessage() {
    if (this.model.post.message.type === PostType.Post) {
      return this.model.post.message.variants[0]
    }

    return this.initializeVariants[0]
  }

  set firstMessage(val: IPostMessage['variants'][0]) {
    if (this.model.post.message.type === PostType.Post) {
      this.model.post.message.variants.splice(0, 1, val)
    }
  }

  mediaLimit = 10

  hasMedia = true

  model: Omit<PostToCreate, 'board_key' | 'board'> = {
    topic_config: {},
    post: {
      message: {
        type: PostType.Post,
        variants: [ ...this.initializeVariants ]
      },
      delayed_actions: [],
      reaction_actions: [],
    },
    schedule: {
      period: {
        conditions: [],
        unit: 'MINUTES',
        interval: 0
      },
      timezone: '',
      run_time: ''
    },
    targets: [ ],
    origin: null,
    style: cloneDeep(this.initStyleModel())
  }

  linkedChannelsGroups: Record<number, number> = {}

  get postIsRecurrent() {
    const { schedule } = this.model

    return !!schedule && !!schedule.period?.interval && schedule.period.interval > 0
  }

  targetsSetter(args: FieldData) {
    args.setter = (value: Array<number>) => {
      const newItem = value.filter(id => !args.model[args.key].includes(id))
      const targetsChannel = this.linkedChannelsGroups[newItem[0]]

      if (newItem.length && !!targetsChannel && value.includes(targetsChannel)) {
        args.model[args.key] = value.filter(id => id !== targetsChannel)

        warningNotification(this.$t('group_or_channel_select_warning_title').toString())
      } else {
        args.model[args.key] = value
      }
    }

    return args
  }

  get postKey() {
    return this.$route.query?.postId?.toString()
  }

  initStyleModel() {
    return {
      disable_notify: true,
      protect_content: false,
      disable_link_preview: false,
      pin: false,
      postfix_message: [  ],
      prefix_message: [  ],
      watermark_type: WatermarkType.None,
    }
  }

  modelCopy: Omit<PostToCreate, 'board_key' | 'board'> | undefined

  isPreviewVisible = true

  isPreviewModalActive = false

  get brandTag() {
    return !this.isBoardUltimate ? BRAND_TAG : null
  }

  get cardTitle() {
    return this.$t(`field_${ snakeCase(this.postPageActionType) }_${ this.postType.toLowerCase() }_title`)
  }

  handlePreviewClick() {
    if (!this.$screen.md) {
      this.isPreviewModalActive = true
    } else {
      this.isPreviewVisible = !this.isPreviewVisible
    }
  }

  @Watch('$screen.lg', { immediate: true })
  onScreenLgChange(state: boolean) {
    if (!state) {
      this.isPreviewVisible = false
    } else {
      this.isPreviewModalActive = false
    }
  }

  @Watch('postType')
  onPostTypeChange(value: PostType) {
    if (this.modelCopy && this.modelCopy.post.message.type === this.postType) {
      this.model = cloneDeep(this.modelCopy)
    } else {
      const postQuestionaire = {
        type: this.postType,
        text: '',
        open_period: 0,
        is_anonymous: true,
        questions: [],
        topics: []
      }

      if (value === PostType.Post) {
        this.model.post.message = {
          type: PostType.Post,
          variants: [ ...this.initializeVariants ]
        }
      } else if (value === PostType.Poll) {
        this.model.post.message = {
          ...postQuestionaire,
          allows_multiple_answers: true,
          type: PostType.Poll
        }
      } else if (value === PostType.Quiz) {
        this.model.post.message = {
          ...postQuestionaire,
          correct_option_id: '',
          explanation: '',
          type: PostType.Quiz,
        }
      }
    }
  }

  //todo
  @Watch('model.post.message.variants')
  onMediaEditorChange(value: any) {
    if (value) {
      this.isCreateButtonDisabled = true
      value.forEach((m: any) => {
        if (!m.text.trim() && !m.attachments.length) {
          this.isCreateButtonDisabled = true
        } else if (m.text || m.attachments.length) {
          this.isCreateButtonDisabled = false
          if (m.attachments.length) {
            m.attachments.forEach((a: any) => {
              a.status === 0 ? this.isCreateButtonDisabled = true : this.isCreateButtonDisabled = false
            })
          }
        } else {
          this.isCreateButtonDisabled = false
        }
      })
    }
  }

  @Watch('model.targets')
  onModelTargetChange() {
    if (this.model.targets && this.model.origin && this.model.targets.includes(this.model.origin)) {
      this.model.origin = null
    }

    this.originSelectRerenderKey += 1
    this.targetSelectRerenderKey += 1
  }

  get hasTargetsTopics() {
    if (this.currentBoard && this.model.targets.length) {
      const forumTargets = this.currentBoard.targets.filter(t => 'topics' in t && this.model.targets.includes(t.id))

      return !!forumTargets.length
    }

    return false
  }

  onTargetTopicsOptionsChange(options: Array<string>) {
    const parsedOptions: Array<TargetTopicsOptionParsedValue> = options.map(o => JSON.parse(o))

    this.model.topic_config = parsedOptions.reduce((acc: PostToCreate['topic_config'], value) => {
      const target = acc[value.target]

      if (target) {
        acc[value.target] = [ ...target, value.topic ]
      } else {
        acc[value.target] = [ value.topic ]
      }

      return acc
    }, {})
  }

  createTargetTopicsOptionValue(targetId: Target['id'], topicId: number) {
    return JSON.stringify({ target: targetId, topic: topicId })
  }

  get topicConfigDefaultOptions() {
    const topic_config = this.model?.topic_config

    if (topic_config) {
      return Object.entries(topic_config).reduce((acc: Array<string>, [ target, topics ]) => {

        acc.push(...topics.map(topic => this.createTargetTopicsOptionValue(+target, topic)))

        return acc
      }, [])
    }

    return []
  }

  get targetsTopics() {
    const forumTargets = this.currentBoard.targets.filter(t => 'topics' in t && this.model.targets.includes(t.id))

    if (forumTargets.length) {
      return forumTargets.reduce((acc: Array<TargetTopicsOption>, target) => {
        const { topics, id, title } = target

        if (topics!.length) {
          const topicsOptions = topics!.reduce((options:Array<SelectOption>, topic) => {
            options.push({
              label: topic.title,
              value: this.createTargetTopicsOptionValue(id, topic.id)
            })

            return options
          }, [])

          acc.push({ title, id, topicsOptions })
        }

        return acc
      }, [])
    }

    return []
  }

  handleDropdownItem(action: ScheduleType): void {
    if (action === ScheduleType.PostNow) {
      this.scheduleType = action

      if (this.postPageActionType === 'new' || this.postPageActionType === 'copy') {
        this.createPost()
      } else if (this.postPageActionType === 'edit') {
        this.editPost()
      }
    }

    if (action === ScheduleType.Test) {
      this.scheduleType = action
      Api.testPost('tg', {
        board: this.$store.getters.currentBoardKey,
        post: { ...this.getPost().message },
        style: this.model.style
      }).then(() => {
        successNotification()
      })
        .catch(errorNotification)
    }

    if (action === ScheduleType.Draft && this.isBoardUltimate) {
      this.scheduleType = action
      this.createPost()
    }
  }

  get targetsToPost() {
    return this.groupsSelectOptions.filter(t => this.model.targets.includes(t.value))
  }

  get topicsSettings(): any {
    const bodIdParam = this.$route.params[EntityTypes.BOT_ID]

    if (this.model.targets?.length === 1) {
      if (this.model.targets?.every(t => this.$store.getters.topicsStructureIfForum(bodIdParam, t) !== null)) {
        return {
          'items': this.$store.getters.topicsIfForum(bodIdParam, this.model.targets[0]),
          'limit': 1
        }
      }

      return undefined
    }

    return undefined
  }

  checkPostPreview(signal: AbortController['signal']) {
    return Api.getPostPreview('tg',
                              {
                                board_key: this.$store.getters.currentBoardKey,
                                post: this.model.post.message,
                                style: this.model.style,
                                apply_watermark: true
                              },
                              { signal }
    )
      .then(({ data }) => data.post)
      .catch((error) => {
        if (!axios.isCancel(error)) {
          errorNotification(error)
        }
      })
  }

  get postPageActionType(): 'new' | 'edit' | 'copy' | 'edit-published' | string | undefined {
    return this.$route.params?.actionType
  }

  onSaveClick() {
    if (this.postPageActionType) {
      if (this.postPageActionType === 'new' || this.postPageActionType === 'copy') {
        this.createPost()
      } else if (this.postPageActionType === 'edit') {
        this.editPost()
      } else if (this.postPageActionType === 'edit-published') {
        this.editPublished()
      }
    }
  }

  editPublished() {
    this.$baseTemplate.loader.open()

    PostsApi.editPublishedPost('tg', {
      board_key: this.$store.getters.currentBoardKey,
      post_key: this.postKey,
      style: this.model.style,
      message: this.firstMessage
    })
      .then(res => this.gotoCalendar())
      .catch(errorNotification)
      .finally(() => {
        this.$baseTemplate.loader.close()
      })
  }

  originOptions() {
    return this.groupsSelectOptions.filter((o) => !this.model.targets.includes(o.value))
  }

  onSelectedStyleChange(selectedStyle: SelectOption): void {
    if (selectedStyle.value !== 'blank' && selectedStyle.value !== 'empty') {
      this.model.style = { ...selectedStyle.value }
    } else {
      this.model.style = cloneDeep(this.initStyleModel())
    }

    this.rerenderPostStylesSetup += 1
  }

  editPost() {
    Api.deletePost('tg', { 'board': this.$store.getters.currentBoardKey, 'key': this.postKey })
      .then(() => {
        this.createPost()
      })
      .catch(errorNotification)
  }

  gotoCalendar() {
    this.$router.push({
      name: 'posts_planner',
      params: {
        id: this.$route.params[EntityTypes.CHAT_ID],
        [EntityTypes.BOT_ID]: this.$route.params[EntityTypes.BOT_ID],
      }
    })
  }

  createPost(): void {
    this.preparePostData()
      .then((data) => {
        if (this.scheduleType === ScheduleType.Draft) {
          Api.savePost('tg', data)
            .then(() => {
              successNotification()

              this.gotoCalendar()
            })
            .catch(errorNotification)
            .finally(() => {
              this.$baseTemplate.loader.close()
            })
        } else {
          Api.createPost('tg', data)
            .then(() => {
              successNotification()

              this.gotoCalendar()
            })
            .catch(errorNotification)
            .finally(() => {
              this.$baseTemplate.loader.close()
            })
        }
      })
  }

  parseStyle(style: Style): Style {
    const styleCopy = { ...style }

    if (this.styleVariant !== 'empty') {
      if (this.model.style?.watermark_type === 'File' && Array.isArray(styleCopy.watermark_data)) {
        styleCopy.watermark_data = styleCopy.watermark_data.join()
      }
    }

    return styleCopy
  }

  preparePostData() {
    return new Promise<Pick<PostToCreate, 'board_key' | 'board'> & PostToCreate['post'] | PostToCreate>(resolve => {
      if (this.scheduleType === ScheduleType.Draft) {
        const postData: Pick<PostToCreate, 'board_key' | 'board'> & PostToCreate['post'] = {
          board_key: this.$store.getters.currentBoardKey,
          ...this.getPost()
        }

        resolve(postData)
      } else {
        const postData: PostToCreate = {
          board: this.$store.getters.currentBoardKey,
          targets: this.model.targets,
          schedule: this.getSchedule(),
          post: this.getPost(),
          style: this.parseStyle(this.model.style),
          origin: this.areOriginsOpen && this.model.origin ? this.model.origin : null,
          topic_config: this.model.topic_config
        }

        if (this.scheduleType === ScheduleType.PostNow) delete postData.schedule

        resolve(postData)
      }
    })
  }

  getSchedule(): Schedule | null {
    if (this.scheduleType === ScheduleType.PostNow || this.scheduleType === ScheduleType.Draft) {
      return null
    } else {
      return {
        run_time: this.getRunTime(),
        period: this.model.schedule!.period?.interval ? {
          interval: Number(this.model.schedule!.period.interval),
          unit: this.model.schedule!.period!.unit,
          conditions: this.getConditions()
        } : null,
        timezone: this.model.schedule!.timezone
      }
    }
  }

  getConditions(): Array<Conditions> | null {
    return this.model!.schedule!.period!.conditions ? this.model!.schedule!.period!.conditions : null
  }

  getRunTime(): string {
    return this.model.schedule!.run_time
  }

  getPost(): PostToCreate['post'] {
    let resPostObject = {
      message: {
        type: this.model.post.message.type,
      } as PostToCreate['post']['message'],
      delayed_actions: this.model.post.delayed_actions,
      reaction_actions: this.model.post.reaction_actions,
    }

    if (this.model.post.message.type === PostType.Post) {
      resPostObject.message = { ...resPostObject.message, variants: this.getVariants() } as IPostMessage

      if (this.postIsRecurrent) {
        resPostObject.message.variants.forEach(v => this.$set(v, 'remove_previous', this.removePreviousMessage))
      } else {
        resPostObject.message.variants.forEach(v => this.$set(v, 'remove_previous', false))
      }
    } else {
      resPostObject.message = { ...resPostObject.message, ...this.model.post.message }
      resPostObject.message.open_period = resPostObject.message.open_period === 0 ? null : resPostObject.message.open_period
    }

    return resPostObject
  }

  getVariants(): IPostMessage['variants'] {
    if (this.model.post.message.type === PostType.Post) {
      return this.model.post.message.variants
    } else {
      return []
    }
  }

  isButtonDisabled(scheduleType?: ScheduleType): boolean {
    const { message } = this.model.post
    const { targets } = this.model

    const targetsLength = targets?.length

    const format = 'YYYY-MM-DD'
    const isDateBefore = moment(this.model.schedule?.run_time.split(' ')[0], format).isBefore(moment(moment().format(format), format))

    if (scheduleType === ScheduleType.PostNow) {
      if (message.type === PostType.Poll) {
        return !targetsLength || this.mainFieldsQuizValidation
      } else  if (message.type === PostType.Quiz) {
        return !targetsLength || !message.correct_option_id || this.mainFieldsQuizValidation
      } else {
        return !targetsLength || this.isCreateButtonDisabled
      }
    }

    if (scheduleType === ScheduleType.Draft || scheduleType === ScheduleType.Test) {
      if (message.type === PostType.Poll) {
        return this.mainFieldsQuizValidation
      } else  if (message.type === PostType.Quiz) {
        return !message.correct_option_id || this.mainFieldsQuizValidation
      } else {
        return this.isCreateButtonDisabled
      }
    }

    if (isDateBefore) {
      return true
    }

    if (message.type === PostType.Post) {
      return !targetsLength || this.isCreateButtonDisabled
    } else if (message.type === PostType.Poll) {
      return !targetsLength || this.mainFieldsQuizValidation
    } else if (message.type === PostType.Quiz) {
      return !targetsLength || !message.correct_option_id || this.mainFieldsQuizValidation
    } else {
      return true
    }
  }

  get mainFieldsQuizValidation(): boolean {
    const { message } = this.model.post

    if (message.type === PostType.Quiz || message.type === PostType.Poll) {
      return !message.questions.length || !message.text || (message.open_period !== null && message.open_period > 600)
    }

    return true
  }

  get dropdownScheduleTypes(): Array<any> {
    return [
      {
        'key': 'post_publish_test',
        'icon': 'tool',
        'action': ScheduleType.Test
      },
      ...this.postPageActionType !== 'edit-published' ? [ {
        'key': 'post_post_now',
        'icon': 'schedule',
        'action': ScheduleType.PostNow
      } ] : [],
      {
        'key': 'post_save_draft',
        'icon': 'folder',
        'action': ScheduleType.Draft,
      }
    ]
  }

  get initializeVariants(): IPostMessage['variants'] {
    return [ {
      attachments: [],
      text: '',
      buttons: [],
      remove_previous: false,
      pin: false,
      disable_link_preview: false,
      disable_notify: false,
      topics: [],
      protect_content: false,
      send_after: 0,
      remove_after: 0
    } ]
  }

  get isBoardUltimate(): boolean {
    return this.$store.getters.isCurrentBoardBrand
  }

  fetchConfigStyles(board: string): void {
    Api.getBoardConfig('tg', { board })
      .then(({ data }) => {
        if (data.config.styles) {
          for (const [ key, value ] of Object.entries(data.config.styles as Record<any, Style>)) {
            if (value.watermark_type === 'File') {
              value.watermark_data = value.watermark_data.split()
            }
            this.selectStyleVariants.push({ label: key, value: value })
          }
        }
      })
      .catch(errorNotification)
  }

  setPostType(type: PostType): void {
    this.postType = type
  }

  getSavedPosts(): void {
    Api.getSavedPosts('tg', { board: this.$store.getters.currentBoardKey })
      .then(({ data }) => {
        const draft = data.posts.find((p: any) => p.key.token_id === Number(this.$route.query.draft))
        this.setPostType(draft.message.type)
        this.model.post.message = { ...draft.message }
        this.modelCopy = cloneDeep(this.model)

      })
      .then(() => {
        this.isObjectSet = true
        this.modelCopy = cloneDeep(this.model)
      })
      .catch(errorNotification)
      .finally(() => this.$baseTemplate.loader.close())
  }

  getPostById(currentBoard: Board): void {
    this.$baseTemplate.loader.open()

    Api.getOriginalPost('tg', { board: this.$store.getters.currentBoardKey, key: this.postKey })
      .then(({ data }) => {
        const editItem = data.post
        this.setPostType(editItem.message.message.type)

        this.model.post.message = { ...editItem.message.message }

        if (editItem.message.message.type === PostType.Post) {
          this.removePreviousMessage = (editItem.message.message.variants as IPostMessage['variants']).some((v) => v.remove_previous)

          if (editItem?.topic_config) {
            this.model.topic_config = editItem.topic_config
          }
        }

        if (editItem.message.delayed_actions && editItem.message.delayed_actions.length) {
          this.model.post.delayed_actions = [ ...editItem.message.delayed_actions ]

          this.arePostActionSet = true
        }

        if (editItem.message.reaction_actions && editItem.message.reaction_actions.length) {
          this.model.post.reaction_actions = [ ...editItem.message.reaction_actions ]

          this.arePostReactionSet = true
        }

        this.model.schedule = { ...editItem.schedule }
        this.model.targets = editItem.target.targets

        if (editItem.target.original !== null) {
          this.areOriginsOpen = true
          this.model.origin = editItem.target.original
        }

        this.isCreateButtonDisabled = false
        if (editItem.schedule.period !== null || (editItem.style)) {

          if (editItem.schedule.period && editItem.schedule.period.conditions) {
            this.initConditions = editItem.schedule.period.conditions
          }
        }

        if (editItem.style !== null && Object.values(editItem.style).some(s => s !== null && s !== 'None')) {
          this.styleVariant = 'blank'
          if (editItem.style.watermark_type === 'File') {
            editItem.style.watermark_data = editItem.style.watermark_data.split()
          }
          this.model.style = { ...editItem.style }

          if (editItem.schedule.period !== null || editItem.style.prefix_message.length > 0 || editItem.style.postfix_message.length > 0 || editItem.style.watermark_type !== WatermarkType.None) {
            this.isExtendedSettingsOpen = true
          }
        }

        if (this.postPageActionType === "edit-published") {
          this.mediaLimit = this.firstMessage.attachments.length
          this.hasMedia = !!this.firstMessage.attachments.length
        }

        this.modelCopy = cloneDeep(this.model)
      })
      .catch(errorNotification)
      .finally(() => {
        this.isObjectSet = true
        this.$baseTemplate.loader.close()
      })
  }

  get groupsSelectOptions(): Array<SelectOptionData> {
    if (this.currentBoard) {
      return this.currentBoard.targets.reduce((acc: Array<SelectOptionData>, t: Target) => {
        acc.push({
          label: t.title,
          value: t.id,
          image: { src: t.photo },
        })

        if (t.channel) {
          this.linkedChannelsGroups[t.id] = t.channel.id
          this.linkedChannelsGroups[t.channel.id] = t.id

          acc.push({
            label: this.$t('groups_channel_option_label', [ t.channel.title, t.title ]).toString(),
            value: t.channel.id,
            image: { src: t.channel.photo },
          })
        }

        return acc
      }, [])
    }

    return []
  }

  get currentBoard(): Board {
    return this.$store.state.postsPlanner.currentBoard
  }

  mounted(): void {
    if (this.currentBoard.targets.length === 1) {
      this.model.targets = [ this.currentBoard.targets[0].id ]
    }

    this.model.schedule ? this.model.schedule.timezone = this.currentBoard.timezone : this.model.schedule = null
    this.fetchConfigStyles(this.$store.getters.currentBoardKey)

    if (this.$route.query.draft) {
      this.$baseTemplate.loader.open()
      this.getSavedPosts()
    } else {
      if (this.postKey) {
        this.getPostById(this.currentBoard)
      } else {
        this.isObjectSet = true
        this.$baseTemplate.loader.close()
      }
    }
  }
}
